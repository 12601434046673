<template>
  <div class="pt-1">
    <account-list-add-new :is-add-new-account-sidebar-active.sync="isAddNewAccountSidebarActive" @refetch-data="refetchData" />

    <!-- Filters -->
    <div class="content-px mb-1">
      <account-list-filters
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :create-at-start-filter.sync="createAtStartFilter"
        :create-at-end-filter.sync="createAtEndFilter"
        :funding-filter.sync="fundingFilter"
        :funding-options="fundingSourceOptions"
        :balance-filter.sync="balanceFilter"
        :payment-date-filter.sync="paymentDateFilter"
      />
    </div>

    <div class="content-px mb-1">
      <b-row>
        <b-col cols="12" sm="6" md="6">
          <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
            <div style="max-width: 18rem; min-width: 15rem; width: 100%">
              <b-form-input v-model="searchQuery" class="drop-shadow-default w-full" placeholder="Search..." />
            </div>
          </div>
        </b-col>

        <b-col cols="12" sm="6" md="6" class="d-flex align-items-center justify-content-end">
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" @click="isAddNewAccountSidebarActive = true">
              <span class="text-nowrap">Create Account</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <div no-body class="mb-0 table-no-top">
      <b-table
        ref="refAccountListTable"
        class="position-relative mb-0"
        :items.sync="fetchAccounts"
        responsive
        :fields="tableColumnsList"
        :filter.sync="searchQuery"
        :filterByFormatted="true"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page.sync="currentPage"
        :per-page.sync="perPage"
      >
        <!-- Column: Student Full Name -->
        <template #cell(student.fullName)="data">
          <b-link
            :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
            class="font-weight-bold text-nowrap text-white mb-0"
            style="font-size: 1.25rem; line-height: 1.75rem"
          >
            {{ data.item.student.fullName }}
          </b-link>
          <b-link
            :to="{ name: 'apps-accounting-receivable-account-view', params: { id: data.item.id } }"
            class="d-flex align-items-center text-muted"
          >
            <small style="font-size: 1.05rem; line-height: 1.35rem">{{ data.item.student.myIpId }}</small>
          </b-link>
        </template>

        <!-- Column: Cohort -->
        <template #cell(cohort)="data">
          <div class="text-nowrap" v-if="data.item.cohort">
            <div
              v-if="data.item.cohort.session.type === 'pathway' && data.item.cohort.session.pathway"
              class="d-flex align-items-center text-nowrap"
              style="gap: 0.625rem"
            >
              <b-avatar
                size="36"
                :src="data.item.cohort.session.pathway.avatar"
                :text="avatarText(data.item.cohort.session.pathway.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
                style="margin-top: 2px"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolvePathwayCohortName(data.item.cohort.session) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Library" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small> {{ data.item.cohort.session.pathway.name }}</small>
                </b-link>
              </div>
            </div>
            <div
              v-if="data.item.cohort.session.type === 'course' && data.item.cohort.session.course"
              class="d-flex align-items-center text-nowrap"
              style="gap: 0.625rem"
            >
              <b-avatar
                size="36"
                :src="data.item.cohort.session.course.avatar"
                :text="avatarText(data.item.cohort.session.course.name)"
                :variant="`light-info`"
                :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
                rounded
              />
              <div class="d-flex flex-column" style="gap: 0">
                <b-link
                  :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
                  class="font-weight-bold text-nowrap text-white mb-0"
                >
                  {{ resolveCourseSectionName(data.item.cohort.session) }}
                </b-link>
                <b-link
                  :to="{ name: 'apps-accounting-receivable-cohort-view', params: { id: data.item.cohort.id } }"
                  class="d-flex align-items-center text-muted text-nowrap"
                >
                  <lucide-icon name="Book" style="width: 14px; height: 14px; margin-right: 1px" />
                  <small> {{ data.item.cohort.session.course.name }}</small>
                </b-link>
              </div>
            </div>
          </div>
          <div class="text-nowrap" v-else>
            <feather-icon
              :id="`account-row-${data.item.id}-set-cohort-icon`"
              icon="PlusCircleIcon"
              class="cursor-pointer"
              size="16"
              @click="startCohortSelect(data.item)"
            />
            <b-tooltip title="Assign Cohort" class="cursor-pointer" :target="`account-row-${data.item.id}-set-cohort-icon`" />
            Unassigned
          </div>
        </template>

        <!-- Column: Attributes - Program -->
        <template #cell(profile.attributes.fundingSource)="data">
          <b-badge
            v-if="data.item.profile"
            :variant="`light-${resolveAccountProgramVariant(data.item.profile.attributes.fundingSource)}`"
            class="text-nowrap text-capitalize"
          >
            {{ title(data.item.profile.attributes.fundingSource) }}
          </b-badge>
          <div v-else class="text-nowrap">Unassigned</div>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveAccountStatusVariant(data.item.status)}`" class="text-nowrap text-capitalize">
            {{ title(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: total -->
        <template #cell(total)="data">
          <span class="text-nowrap">{{ money(data.item.total) }} ({{ data.item.charges.length }})</span>
        </template>

        <!-- Column: balance -->
        <template #cell(totalPaid)="data">
          <span class="text-nowrap"
            >{{ money(data.item.totalPaid) }} ({{ data.item.payments.filter((a) => a.status === 'paid').length }})</span
          >
        </template>

        <!-- Column: balance -->
        <template #cell(balance)="data">
          <span class="text-nowrap">{{ money(data.item.balance) }}</span>
        </template>

        <!-- Column: Cohort -->
        <template #cell(createdAt)="data">
          {{ moment(data.item.createdAt).format('L') }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item
              v-if="$can('accounting.receivable.account.update')"
              @click="$router.push({ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <!-- <div class="text-nowrap"> -->
          <!-- <feather-icon
              :id="`account-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
          
            />
            <b-tooltip title="Edit Account" class="cursor-pointer" :target="`account-row-${data.item.id}-edit-icon`" placement="top" /> -->

          <!-- <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>

              <b-dropdown-item :to="{ name: 'apps-accounting-receivable-account-edit', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveAccount(data.item.id)">
                <feather-icon icon="ArchiveIcon" />
                <span class="align-middle ml-50">Archive</span>
              </b-dropdown-item>
            </b-dropdown> -->
          <!-- </div> -->
        </template>
      </b-table>
      <div class="mx-2 mb-50">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAccounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <account-add-to-cohort :is-add-cohort-open.sync="isAddCohortOpen" v-on:refresh="refetchData" :account="cohortAccount" />
    <account-upload-document
      :is-upload-document-open.sync="upload.isUploadDocumentOpen"
      :account="upload.account"
      :document-category="upload.documentCategory"
      :document-type="upload.documentType"
      v-on:refresh="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText, title } from '@core/utils/filter';
import AccountListFilters from './AccountListFilters.vue';
import useUsersList from '../../../../user/users-list/useUsersList';
import useAccountsList from './useAccountsList';
import AccountListAddNew from './AccountListAddNew.vue';
import moment from 'moment';
import AccountUploadDocument from './components/AccountUploadDocument.vue';
import AccountAddToCohort from './components/AccountAddToCohort.vue';
import router from '@/router';
import usePathwayCohorts from '@/views/apps/roster/pathways/cohorts/usePathwayCohorts';
import useCourseSections from '@/views/apps/roster/courses/sections/useCourseSections';

export default {
  components: {
    AccountAddToCohort,
    AccountUploadDocument,
    AccountListFilters,
    AccountListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
  },
  data: () => {
    return {
      isAddCohortOpen: false,
      cohortAccount: undefined,

      upload: {
        isUploadDocumentOpen: false,

        account: undefined,
        documentType: undefined,
        documentCategory: undefined,
      },
    };
  },
  methods: {
    money(v) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(v);
    },
    startCohortSelect(account) {
      this.cohortAccount = account;
      this.isAddCohortOpen = true;
    },
    startUpload(account, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        account,
        documentCategory,
        documentType,
      };
    },
    archiveAccount(accountId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this account?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('receivableStoreModule/updateAccount', {
              id: accountId,
              accountData: {
                status: 'archived',
              },
            })
            .then((response) => {
              this.refetchData();
            });
        }
      });
    },
  },
  metaInfo() {
    return {
      title: 'AR Accounts',
    };
  },
  mounted() {
    if (this.$route.query.op === 'create') {
      this.isAddNewAccountSidebarActive = true;
    }
  },
  computed: {
    hasApplicableFilter() {
      return true;
    },
  },
  setup() {
    const isAddNewAccountSidebarActive = ref(false);

    const {
      fetchAccounts,
      tableColumnsList,
      perPage,
      currentPage,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // UI

      resolveAccountStatusVariant,
      resolveAccountProgramVariant,
      resolveAccountYNVariant,
      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,
      balanceFilter,

      createAtStartFilter,
      createAtEndFilter,

      //
      paymentDateFilter,
    } = useAccountsList();

    const { resolvePathwayCohortName } = usePathwayCohorts();

    const { resolveCourseSectionName } = useCourseSections();

    return {
      // Sidebar
      isAddNewAccountSidebarActive,

      fetchAccounts,
      tableColumnsList,
      perPage,
      currentPage,
      totalAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAccountListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      // UI
      resolveAccountStatusVariant,
      resolveAccountProgramVariant,
      resolveAccountYNVariant,

      // Extra Filters
      statusFilter,
      statusOptions,
      fundingFilter,
      fundingSourceOptions,

      createAtStartFilter,
      createAtEndFilter,
      balanceFilter,
      //
      paymentDateFilter,

      resolvePathwayCohortName,
      resolveCourseSectionName,

      //
      moment,
    };
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
