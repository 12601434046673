<template>
  <b-modal
    id="modal-lg"
    ok-title="Add to Cohort"
    centered
    size="lg"
    title="Search for cohorts"
    ref="modal"
    @hidden="() => $emit('update:is-add-cohort-open', false)"
    :visible="isAddCohortOpen"
    :hide-footer="true"
  >
    <b-card no-body>
      <b-row>
        <b-col cols="12" lg="6">
          <b-pagination v-model="currentPage" :total-rows="totalResults" :per-page="perPage" aria-controls="table-test"></b-pagination>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group class="justify-content-end">
            <div class="d-flex align-items-center">
              <b-form-checkbox id="restricted-search" v-model="restricted" name="restricted-search" class="mr-3">
                Restricted
              </b-form-checkbox>

              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" @input="handleSearch" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        id="table-test"
        ref="refCohortTable"
        :busy.sync="isBusy"
        :items="cohortProvider"
        :fields="cohortFields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchTerm"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :responsive="true"
      >
        <template #cell(status)="data">
          {{ title(data.item.status) }}
        </template>

        <template #cell(program.name)="data">
          {{ data.item.program && data.item.program.name ? data.item.program.name : 'n/a' }}
        </template>

        <template #cell(program.startDate)="data">
          {{ data.item.program && data.item.program.startDate ? moment(data.item.program.startDate).format('l') : 'n/a' }}
        </template>

        <template #cell(actions)="data">
          <b-button @click="assignCohort(data.item)" variant="primary" size="sm">Select</b-button>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BFormCheckbox,
  BCard,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import Prism from 'vue-prism-component';
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';
import moment from 'moment';
import { title } from '@core/utils/filter';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
  },
  data() {
    return {
      isBusy: false,
      searchTerm: '',
      perPage: 10,
      currentPage: 1,
      totalResults: 0,

      cohortFields: [
        { key: 'actions' },
        { key: 'name', sortable: true },
        { key: 'program.name', sortable: true, label: 'Start' },
        { key: 'program.startDate', sortable: true, label: 'Start' },
        { key: 'status', sortable: true },
      ],

      restricted: true,

      sortBy: 'createdAt',
      sortDesc: true,
    };
  },
  props: {
    isAddCohortOpen: {
      type: Boolean,
    },
    account: {
      type: Object,
    },
  },
  watch: {
    restricted(val) {
      this.$refs.refCohortTable.refresh();
    },
  },
  methods: {
    cohortProvider(ctx) {
      let adl = {};

      console.log(`prof`, this.account);

      const promise = store.dispatch('receivableStoreModule/fetchCohorts', {
        search: ctx.filter,
        limit: ctx.perPage,
        page: ctx.currentPage,
        sortBy: `${ctx.sortBy || 'createdAt'}:${ctx.sortDesc ? 'desc' : 'asc'}`,

        // populate: 'student',
        ...adl,
      });

      return promise
        .then((response) => {
          const { results, totalResults } = response.data;
          this.rows = results;
          this.totalResults = totalResults;
          return results;
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching cohort list-pending',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });

          return [];
        });
    },
    assignCohort(cohort) {
      store
        .dispatch('receivableStoreModule/updateAccount', {
          id: this.account.id,
          accountData: { cohort: cohort.id },
        })
        .then((response) => {
          const { data: account } = response;

          this.$emit('update:is-add-cohort-open', false);
          this.$emit('refresh', true);

          return this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'EditIcon',
              variant: 'success',

              title: 'Added account to cohort',
              text: 'This student has been assigned to this cohort',
            },
          });
        })
        .catch((err) => {
          console.error(`failed to add account to cohort`, err);
        });
    },
    handleSearch(searching) {
      this.searchTerm = searching;
    },
  },

  setup() {
    const toast = useToast();

    return { toast, title, moment };
  },
};
</script>
