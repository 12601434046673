<template>
  <b-modal
    id="modal-upload-document"
    ref="modal-upload-document"
    title="Select local file"
    cancel-variant="outline-secondary"
    @ok="openUploadEnroll"
    @hidden="() => $emit('update:is-upload-document-open', false)"
    v-model="isUploadDocumentOpen"
    centered
  >
    <b-row>
      <!-- Account Info: Left col -->
      <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
        <!-- Account Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="accountData.avatar" :text="avatarText(accountData.fullName)" :variant="`light-info`" size="48px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ accountData.fullName }}
              </h4>
              <span class="card-text">{{ accountData.email }}</span>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Account Id</span>
            </th>
            <td class="pb-50">
              {{ accountData.accountId }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Funding</span>
            </th>
            <td class="pb-50">
              {{ title(accountData.attributes.fundingSource) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">VA Submission</span>
            </th>
            <td class="pb-50">
              {{ title(accountData.attributes.submittedToVa) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50">
              {{ title(accountData.status) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <hr />

    <b-form>
      <b-form-group
        label="Choose the name of the document you will attach. Leave empty to default to the name of the file from your computer."
        label-for="select-upload-name"
      >
        <b-form-input id="select-upload-name" v-model="upload.fileName" :placeholder="`${title(documentType)}.pdf`"> </b-form-input>
      </b-form-group>

      <b-form-group label="Choose the document you would like to upload and attach to this account." label-for="select-upload-document">
        <b-form-file
          id="select-upload-document-file"
          accept=".jpg, .jpeg, .png, .pdf, .doc"
          :capture="true"
          :autofocus="true"
          @input="onFileSelect"
          v-model="upload.file"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { avatarText, title } from '@core/utils/filter';
import { BModal, BForm, BFormGroup, BFormInput, BFormFile, BCard, BButton, BRow, BCol, BAvatar } from 'bootstrap-vue';
import useAccountsList from '../useAccountsList';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';

export default {
  name: 'AccountUploadDocument',
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    isUploadDocumentOpen: {
      type: Boolean,
    },
    account: {
      type: Object,
    },
    documentCategory: {
      type: String,
    },
    documentType: {
      type: String,
    },
  },
  data: () => {
    return {
      upload: {
        type: { value: 'select_value', label: 'Select Type' },
        fileName: undefined,
        file: undefined,
      },
    };
  },
  methods: {
    onFileSelect() {
      if (!this.upload.fileName) {
        this.upload.fileName = this.upload.file.name;
      }
    },
    async openUploadEnroll() {
      const formData = new FormData();
      formData.append('document', this.upload.file);

      try {
        const documentResp = await store
          .dispatch('app-account/createAccountDocument', {
            accountId: this.accountData.id,
            payload: {
              name: this.upload.fileName || this.upload.file.name,
              category: this.documentCategory,
              subCategory: this.documentType,
            },
          })
          .catch((error) => console.log(`Failed to create document`, error));

        if (documentResp.status !== 201) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: documentResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        const document = documentResp.data;

        const uploadResp = await store
          .dispatch('app-account/uploadAccountDocument', {
            accountId: this.accountData.id,
            documentId: document.id,
            formData,
          })
          .catch((error) => console.log(`Failed to upload account document`, error));

        if (uploadResp.status !== 200) {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: uploadResp.data.message || 'An unknown error has occurred.',
            },
          });
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'CheckIcon',
            variant: 'success',
            title: 'Successfully uploaded document',
            text: `The account's account has been updated to match the uploaded document`,
          },
        });

        this.$emit('refresh');
        this.upload = { fileName: undefined, file: undefined };
      } catch (e) {
        if (e.response) {
          const { response } = e;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: response.data.message || 'An unknown error has occurred.',
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create and upload document.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: e.message,
            },
          });
        }
      }
    },
  },
  computed: {
    accountData() {
      return this.account || { attributes: {} };
    },
  },
  mounted() {
    console.log(this.isUploadDocumentOpen, this.accountId, this.documentType);
  },
  setup() {
    return {
      avatarText,
      title,
    };
  },
};
</script>

<style scoped></style>
